<style scoped>
td {
  padding: 5px;
}

.listtable td {
  padding: 0px;
}

.description {
  font-size: 1em;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

.description:hover {
}

.person {
  position: relative;
}

.person i {
  position: absolute;
  bottom: 60px;
  right: 0px;
  color: black;
  opacity: 0.8;
  filter: drop-shadow(1px 1px white);
  cursor: pointer;
}

.fa {
  font-size: 20px;
}

.fa-minus-circle {
  font-size: 14px;
}

@media only screen and (min-width: 700px) {
  td {
    min-width: 150px;
  }

  .description {
    font-size: 2em;
  }
}
</style>


<template>
  <div v-if="authenticated && !badge">
    <i class="fa fa-cog fa-spin"></i>
  </div>
  <div v-if="authenticated && badge">
    <table>
      <tr>
        <td>
        </td>
        <td>
          <BadgeDisplay :badge="badge" :manager="manager" mode="big"/>
          <div v-if="manager">
            <input type="text" class="description" v-on:blur="onDescription" v-model="badge.description" :size="Math.max(badge.description.length, 20)"/>
          </div>
          <span v-if="!manager">
            <h1>{{badge.description}}</h1>
          </span>
          <span v-if="description_update">
            <i class="fa fa-solid fa-cog fa-spin"></i>
          </span>
        </td>
        <td></td>
      </tr>
      <tr>
        <td align=right valign=top>
          Holders
        </td>
        <td width="100%">
          <div style="display: flex; width: 100%">
            <div style="flex-basis: 100%;">
              <div v-if="!listdisplay">
                <span v-for="member in badge.members" :key="member" class="person">
                  <PersonDisplay :email="member" :badge="badge.id"/>
                  <span v-if="manager">
                    <i class="fa fa-solid fa-minus-circle" @click="onRemoveMember(member)"></i>
                  </span>
                </span>
              </div>
              <table v-if="listdisplay" class="listtable">
                <tr v-for="member in badge.members" :key="member">
                  <td class="listicon">
                    <span class="person">
                      <PersonDisplay :email="member" :badge="badge.id"/>
                      <span v-if="manager">
                        <i class="fa fa-solid fa-minus-circle" @click="onRemoveMember(member)"></i>
                      </span>
                    </span>
                  </td>
                  <td>
                    <router-link :to="{name: 'person', params: {email: member}}">
                      <PersonNameDisplay :email="member"/>
                    </router-link>
                  </td>
                  <td class="listitem">
                    <router-link :to="{name: 'person', params: {email: member}}">
                      {{member}}
                    </router-link>
                  </td>
                </tr>
              </table>
            </div>
            <div>
              <i class="fa fa-list" style="cursor: pointer" v-on:click="listMembersToggle"></i>
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <td align=right valign=top>
          Administrator
        </td>
        <td>
          <span v-for="m in badge.managers" :key="m" class="person">
            <PersonDisplay :email="m" :badge="badge.id"/>
            <span v-if="manager && m != profile_email">
              <i class="fa fa-solid fa-minus-circle" @click="onRemoveManager(m)"></i>
            </span>
          </span>
        </td>
      </tr>
      <tr>
        <td align=right>ID</td>
        <td>
          {{badge.id}}
        </td>
      </tr>  
      <tr v-if="manager">
        <td align="right" valign="top"></td>
        <td>
          <div>
            {{badge.members.length}} total members
          </div>
          <div v-if="manager"><AddPeople type="members" message="Add Members" :badge="badge.id"/></div>
          <div v-if="manager"><AddPerson type="members" message="Add Member" :badge="badge.id"/></div>
          <div v-if="manager"><AddPerson type="managers" message="Add Administrator" :badge="badge.id"/></div>
          <div v-if="manager">
            <br>
            Upload new icon:<br>
            <IconUpload @update="onIconUpdate"/>
            <span v-if="icon_data && !icon_update">
              <button v-on:click="submitIcon">Submit</button>
            </span>
            <span v-if="icon_update">
              <i class="fa fa-solid fa-cog fa-spin"></i>
            </span>
          </div>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import { collection, doc, getDoc, updateDoc, arrayRemove } from 'firebase/firestore';
import BadgeDisplay from '@/components/BadgeDisplay.vue'
import PersonDisplay from '@/components/PersonDisplay.vue'
import PersonNameDisplay from '@/components/PersonNameDisplay.vue'
import IconUpload from '@/components/IconUpload.vue'
import AddPerson from '@/components/AddPerson.vue'
import AddPeople from '@/components/AddPeople.vue'
import { loadPeople } from "@/google"
import { setError } from "@/error"

export default {
  name: 'BadgeView',
  inject: [
    'authenticated',
    'firebase_db',
    'profile_email'
  ],
  data: function () {
    return {
      badge: undefined,
      manager: false,
      description_update: false,
      listdisplay: false,
      icon_data: undefined,
      icon_update: false
    }
  },
  components: {
    BadgeDisplay,
    PersonDisplay,
    PersonNameDisplay,
    AddPerson,
    AddPeople,
    IconUpload
  },
  watch: {
    authenticated() {
      if (this.authenticated) {
        this.load()
      }
    },
    description() {
      console.log(this.description)
    }
  },
  created: function () {
    if (this.authenticated) {
      this.load()
    }

    this.$watch(
      () => this.$route.params, () => {
        this.load();
      }
    )
  },
  methods: {
    onDescription: function () {

      var description = this.badge.description

      if (!description) {
        return
      }

      description = description.trim()

      if (description.length < 1) {
        return
      }

      this.description_update = true
      updateDoc(doc(this.firebase_db, "badges", this.badge.id), {
        "description": description
      }).then(() => {
        this.description_update = false
      }).catch((error) => {
        setError(error)
      })
    },
    load: async function () {
      var id = this.$route.params.id;
      if (id != undefined) {
        // Before we load badges make sure the person database is cached, this hack
        // prevents concurrent calls to the People API, slowing the application down.
        await loadPeople(localStorage['oauth_access_token'])

        const badgesRef = collection(this.firebase_db, "badges");
        const q = doc(badgesRef, id);

        getDoc(q).then(doc => {
          if (doc.data() === undefined) {
            setError("Could not locate badge " + id);
            return;
          }

          // Directly load the badge from the result, set the ID to the document ID.
          this.badge = doc.data();
          this.badge.id = doc.id;

          // Check to see if the current authenticated user is a manager of this badge.
          if (this.badge.managers && this.badge.managers.includes(this.profile_email)) {
            this.manager = true
          } else {
            this.manager = false
          }

          // If the badge has an empty description..
          if (this.badge.description === undefined) {
            this.badge.description = "<No description>"
          }

          // Sort managers and members alphabetically.
          this.badge.managers.sort()

          if (this.badge.members) {
            this.badge.members.sort()
          } else {
            this.badge.members = []
          }
        }).catch(error => {
          console.log(error);
        });
      }
    },
    onRemoveManager: function(m) {
      updateDoc(doc(this.firebase_db, "badges", this.badge.id), {
        'managers': arrayRemove(m)
      }).then(() => {
        this.load()
      }).catch((error) => {
        setError(error)
      })
    },
    onRemoveMember: function(member) {
      updateDoc(doc(this.firebase_db, "badges", this.badge.id), {
        'members': arrayRemove(member)
      }).then(() => {
        this.load()
      }).catch((error) => {
        setError(error)
      })
    },
    onIconUpdate: function(data) {
      this.icon_data = data
    },
    listMembersToggle: function() {
      this.listdisplay = !this.listdisplay
    },
    submitIcon: function() {
      this.icon_update = true

      updateDoc(doc(this.firebase_db, "badges", this.badge.id), {
        "icon_base64": this.icon_data
      }).then(() => {
        this.icon_update = false
        this.load()
      }).catch((error) => {
        setError(error)
      })
    }
  }
}
</script>
